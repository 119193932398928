@use 'index.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Montserrat, Roboto, 'Helvetica Neue', sans-serif;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
  }
}
