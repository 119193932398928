@use 'sass:map';
@use '@angular/material' as mat;
@use './themes/standard-theme' as standard;

@mixin theme-colors($theme) {
  $_background: map.get(standard.$picnic-theme, 'background');
  $_foreground: map.get(standard.$picnic-theme, 'foreground');

  $_primary: map.get($theme, 'primary');
  $_accent: map.get($theme, 'accent');

  @include mat.all-component-themes($theme);

  --primary-color: #{mat.get-color-from-palette($_primary, 'default')};
  --primary-color-A100: #{mat.get-color-from-palette($_primary, 'A100')};
  --primary-color-50: #{mat.get-color-from-palette($_primary, 50)};
  --primary-text-color: #{mat.get-color-from-palette($_primary, 'A200-contrast')};
  --color-disabled: #{mat.get-color-from-palette($_primary, 'lighter')};

  --accent-color: #{mat.get-color-from-palette($_accent, 'default')};
  --accent-color-50: #{mat.get-color-from-palette($_accent, 50)};
  --accent-text-color: #{mat.get-color-from-palette($_accent, 'text')};

  --background-color: #{map.get($_background, 'background')};
  --disabled-background-color: #{map.get($_foreground, 'disabled-button')};
}

:root {
  @include theme-colors(standard.$picnic-theme);
  --success-color: #5cb85c;
  --error-color: red;
  --border-radius: 4px;
  --border-color: rgb(225, 225, 225);
  --box-shadow: 0px 0px 7px 0px rgb(52 52 52 / 8%);

  --card-header-height: 55px;
  --primary-background-text-color: white;
}

.liberty {
  @include theme-colors(standard.$liberty-theme);
}

.greatwaters {
  @include theme-colors(standard.$greatwaters-theme);
}
