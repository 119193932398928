@use 'utils';

mat-card.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: var(--box-shadow);
}

mat-chip {
  fa-icon {
    width: 1em;
    height: 1em;
  }
}

fa-icon {
  display: block;
  width: 24px;
  height: 24px;

  svg.svg-inline--fa {
    width: 100%;
    height: 100%;
    vertical-align: 0px;
  }
}

.no-underline {
  .mat-form-field-underline {
    display: none;
  }
}

mat-form-field {
  .mat-form-field-outline {
    background-color: white;
  }
}

.StripeElement {
  position: relative;
  border-radius: var(--border-radius);
  border: solid 1px var(--border-color);
  background-color: white;
  outline: 0px;
  padding: 17px 25px;
  margin: 10px auto;
  box-sizing: initial;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: var(--border-radius);
      border: 2px solid black;
    }
  }

  &.StripeElement--focus {
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: var(--border-radius);
      border: 2px solid var(--primary-color);
    }
  }

  &.StripeElement--invalid {
    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: var(--border-radius);
      border: 2px solid var(--error-color);
    }
  }
}

//make fa icons compatible with angular material buttons and anchors
a,
button {
  &.mat-menu-item,
  &.mat-button-base {
    fa-icon {
      display: inline-block;
      width: 1em;
      height: 1em;

      svg.svg-inline--fa {
        vertical-align: 1em;
      }
    }
  }

  &.mat-menu-item {
    fa-icon {
      svg.svg-inline--fa {
        vertical-align: -2px;
      }
    }
  }
}

snack-bar-container.error-snackbar {
  font-weight: 400;
}

mat-toolbar.mat-toolbar {
  background-color: white;
}

mat-checkbox {
  &.error-checkbox {
    .mat-checkbox-frame {
      border-color: red;
    }
  }

  // fix checkbox multiline label
  label.mat-checkbox-layout {
    white-space: normal;

    .mat-checkbox-inner-container {
      margin-top: 3px;
    }
  }
}

// fix radio button multiline label
mat-radio-button {
  label.mat-radio-label {
    white-space: normal;

    .mat-radio-container {
      align-self: start;
      margin-top: 3px;
    }
  }
}

// reset styles of markdown component
markdown {
  p {
    margin-bottom: 0px;
  }
}

.sr-only {
  display: none;
}

//TODO remove below after refactoring admin module
//*********************//

app-admin-dashboard {
  fa-icon {
    display: inline;
    width: auto;
    height: auto;

    svg.svg-inline--fa {
      height: 1em;
      width: auto;
      vertical-align: -3px;
    }
  }
}

ngx-stripe-card {
  .card {
    border: none !important;
  }
}

.picnic-custom-tab {
  font-weight: 600;
  padding-top: 20px;
  font-size: 14px;

  .nav-link {
    color: #312a9e !important;
  }
}

.accountant-job-board-popover {
  popover-container {
    min-width: 350px;
  }
}

.client-jobs-popover {
  popover-container {
    min-width: 250px;
  }
}

.marketing-footer-menu {
  .mat-menu-item {
    text-transform: uppercase;
    color: #312a9e;
    font-weight: 600;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #ebe9ff !important;
    }

    img {
      width: 30px;
      margin-right: 10px;
    }
  }
}

.picnic-nav-menu-white,
.picnic-footer-menu-white {
  a {
    color: white;
  }
}

.picnic-nav-menu {
  a {
    color: rgb(58, 58, 58);
  }
}

.accountantPaymentConfirmationModal {
  margin-top: 150px;
  margin-left: 43.33%;
}

app-picnic-info-list {
  app-picnic-info-item {
    margin: 10px 0px;
    flex-basis: 50%;
    flex-wrap: wrap;

    &[single] {
      flex-basis: 100%;
    }
  }
}

.picnic-login-modal,
.picnic-sign-up-modal {
  max-width: 420px;
}

bs-modal-backdrop.modal-backdrop {
  z-index: 100;
}
modal-container.modal {
  z-index: 101;
}

.picnic-incomes-modal {
  max-width: 900px;
}

.picnic-signature-modal {
  max-width: 1200px;
}

.col-1-3quarter {
  flex-basis: 14.5833349999%;
  max-width: 14.5833349999%;
}

.col-1med {
  flex-basis: 12.499999%;
  max-width: 12.499999%;
}

.col-1quarter {
  flex-basis: 10.41666666%;
  max-width: 10.41666666%;
}

.col-2med {
  flex-basis: 20.833333%;
  max-width: 20.833333%;
}

.col-med {
  flex-basis: 4.1666666%;
  max-width: 4.1666666%;
  padding-left: 0px;
}

.col-3quarter {
  flex-basis: 6.2499999%;
  max-width: 6.2499999%;
}

/********************/

.opacity {
  opacity: 0.85;
}

.input-group {
  margin-bottom: 15px;
}

.container-onboarding-accountant {
  margin-top: 150px;
  min-height: 650px;
}

.picnic-center {
  text-align: center;
}

.picnic-container {
  margin: 0 auto;
}

.picnic-workflow-step-container {
  max-width: 520px;
}

.picnic-center {
  text-align: center;
}

.picnic-container {
  margin: 0 auto;
}

.picnic-workflow-step-container {
  max-width: 520px;
}

.picnic-input-error {
  border: solid 2px red !important;
}

.picnic-input-text {
  height: 40px;
  border-radius: 28.5px;
  box-shadow: 0 25px 55px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(151, 151, 151, 0.14);
  outline: 0px;
  padding: 25px;
  width: 100%;
  margin: 10px auto;

  ::placeholder {
    font-size: 13px;
    color: #4a4a4a;
  }
}

.picnic-input-text-with-hover:hover {
  cursor: pointer;
  background-color: #7771ff;
  color: #ffff;
}

.picnic-input-text-active {
  background-color: #7771ff;
  color: #ffffff;
}

.picnic-input-icon-right {
  text-align: right;
  position: absolute;
  color: #9b9b9b;
  top: 25px;
  right: 47px;
  cursor: pointer;
}

.picnic-input-checkbox {
  height: 22px;
  width: 22px;
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(151, 151, 151, 0.14);
}

.picnic-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
}

.picnic-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border-radius: 4px;
}

.picnic-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(151, 151, 151, 0.14);
}

.picnic-checkbox:hover input ~ .picnic-checkmark {
  background-color: #7771ff;
}

.picnic-checkbox input:checked ~ .picnic-checkmark {
  background-color: #2e2699;
}

.picnic-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.picnic-checkbox input:checked ~ .picnic-checkmark:after {
  display: block;
}

.picnic-checkbox .picnic-checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.picnic-select {
  border-radius: 28.5px;
  outline: 0px;
  padding: 14px 25px;
  padding-right: 40px;
  width: 100%;
  margin: 10px auto;
  background-color: #ffffff;
  box-shadow: 0 25px 55px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(151, 151, 151, 0.14);

  select {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border: none;
  }

  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

.picnic-select::after {
  content: '\f107';
  font-family: 'FontAwesome';
  color: #979797;
  right: 40px;
  top: 25px;
  position: absolute;
  pointer-events: none;
}

.picnic-radio {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.picnic-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  border: solid 1px #312a9e;
}

.picnic-radio:hover input ~ .checkmark {
  background-color: #ffffff;
  border: solid 1px #312a9e;
}

.picnic-radio input:checked ~ .checkmark {
  background-color: #ffffff;
  border: solid 1px #312a9e;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.picnic-radio input:checked ~ .checkmark:after {
  display: block;
}

.picnic-radio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #312a9e;
}

.required::after {
  content: ' *';
  color: red;
}

.picnic-form-error-text {
  font-size: 13px;
  margin-left: 30px;
  color: #dc3545;
  font-style: italic;
}

form .row {
  margin-top: 15px;
}

.picnic-label {
  position: absolute;
  top: -10px;
  left: 40px;
  font-size: 11px;
  color: #4a4a4a;
}

.picnic-btn {
  min-width: 194px;
  height: 50px;
  border-radius: 28.5px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: 0;
  font-weight: bold;
  padding: 10px 20px;
}

.picnic-btn-primary {
  background-image: linear-gradient(to bottom, #7771ff, #2e2699);
  background-color: transparent;
  -webkit-box-shadow: -1px 9px 10px -5px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -1px 9px 10px -5px rgba(0, 0, 0, 0.27);
  box-shadow: -1px 9px 10px -5px rgba(0, 0, 0, 0.27);
}

.picnic-btn-secondary {
  background-color: #f8b90d;
  color: #312a9e;
  border: none;
}

.picnic-btn-outline {
  background-color: white;
  border: solid 1px #3932a9;
  color: #3932a9;
  -webkit-box-shadow: -1px 9px 10px -5px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -1px 9px 10px -5px rgba(0, 0, 0, 0.27);
  box-shadow: -1px 9px 10px -5px rgba(0, 0, 0, 0.27);
}

.picnic-btn-short {
  width: 100px;
  height: 40px;
}

.picnic-btn-primary:hover {
  background-image: none;
  background-color: #756efc;
}

.picnic-btn-outline:hover {
  background-image: linear-gradient(to bottom, white, #756efc46);
  background-color: transparent;
}

.picnic-btn-disabled {
  background-color: #d5d4d6;
  outline: 0;
}

.picnic-input-as-btn:hover {
  cursor: pointer;
  background-color: #756efc;
  color: #ffffff;
}

.picnic-short-card {
  width: 170px;
  height: 170px;
  border-radius: 21px;
  box-shadow: 0 25px 55px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(151, 151, 151, 0.14);
  margin: 10px auto;
  cursor: pointer;
}

.card-active {
  background-color: rgba(119, 112, 254, 0.25);
}

.picnic-card-text-footer {
  color: #4a4a4a;
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: 55px;
  font-size: 12px;
  font-weight: bold;
}

.picnic-card-icon {
  position: relative;
  top: 35px;
  height: 60px;
}

.picnic-card-middle {
  width: 249px;
  height: auto;
  border-radius: 11px;
  border: solid 1px #979797;
  padding: 20px;
  font-size: 13px;
}

.grey-card {
  color: #3a3a3a;
}

.important-card {
  font-weight: bold;
}

.picnic-document-card {
  max-width: 454px;
  height: 66px;
  border-radius: 5px;
  box-shadow: 0 25px 55px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px rgba(151, 151, 151, 0.19);
  padding: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.picnic-customer-opinions-card {
  max-width: 310px;
  min-height: 255px;
  border-radius: 7px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.11);
  font-size: 16px;
  line-height: 1.54;
  letter-spacing: 0.2px;
  padding: 26px 17px 70px;
  margin: 0 auto;
}

.picnic-modal {
  max-height: 440px;
  overflow: auto;
}

.text-opacity {
  opacity: 0.75;
}

.text-link {
  color: #312a9e;
  cursor: pointer;
}

.picnic-close-modal {
  font-size: 30px;
  cursor: pointer;
}

.w100 {
  width: 100%;
}

.l {
  float: left;
}

.p25 {
  width: 25%;
}

.pointer {
  cursor: pointer;
}

.content-marketing {
  margin-top: 105px;
  width: 100%;
}

a.mark-nav-link-active {
  color: #312a9e !important;
}

a.mark-acc-nav-link-active {
  text-decoration: none;
  background-image: url('/assets/image/underline.png');
  background-size: 60% 18px;
  /* stretch to link width but keep its height */
  background-repeat: no-repeat;
  /* do not repeat the image */
  background-position: bottom;
  padding-bottom: 14px;
  /* move image below the text */
}

.theme-green .bs-datepicker-head,
.theme-green .bs-datepicker-body table td span.selected {
  background-color: #2e2699;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #2e2699;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.phone {
  display: none !important;
}

img {
  max-width: 100%;
}

.modal {
  overflow-y: -moz-scrollbars-none;
}

.acd-content {
  padding: 40px 50px;
}

.strong {
  font-weight: 600;
  color: #312a9e;
}

.x-hellosign-embedded {
  z-index: 2000;
}

@media (max-width: 768px) {
  .no-phone {
    display: none !important;
  }

  .phone {
    display: inline-block !important;
  }

  body {
    overflow-x: hidden !important;
  }

  .text-center-phone {
    text-align: center;
  }
}

.board-container {
  box-shadow: 0 25px 55px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px rgba(151, 151, 151, 0.14);
  border-radius: 5px;
}

.board-title-bar {
  height: 65px;
  width: 100%;
  border-bottom: solid 2px rgba($color: #000000, $alpha: 0.1);
}

.board-title {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.93;
  letter-spacing: 0.03px;
  color: #000000;
}

.picnic-capitalize {
  text-transform: capitalize;
}

.sticky .mat-optgroup-label {
  position: sticky;
  z-index: 1; // appear above mat-option
  top: 0; // fix at the top
  background-color: white; // don't see mat-option when scrolling
  box-shadow: 1px 0px 5px 0px grey; // shadow at the bottom
}

.faqs-accordion,
.mat-expansion-indicator::after {
  color: #312a9e;
}

.invisible {
  visibility: hidden !important;
}

.icon-btn .mat-button-wrapper {
  display: flex;
  align-items: center;
  column-gap: 6px;
}
